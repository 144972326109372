import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  contents: {
    id: 'Contents',
    defaultMessage: 'Contents',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
});

export function pdfBlockSchema() {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ["linkedObject"],
      },
    ],
    properties: {
      linkedObject: {
        title: "PDF to show",
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: [],
        allowExternals: true,
        selectableTypes: ["File", "glossary_pdf"]
      },
    },
  };
}
