import { PDFViewer } from "./Viewer";

import { flattenToAppURL } from "@plone/volto/helpers";

export function PDFBlockView({ data, properties }) {
  let pdfPath = properties?.file?.download;
  const linkedPdf = data.linkedObject?.[0]?.["@id"];
  if (linkedPdf) {
    pdfPath = `${linkedPdf}/@@download/file`;
  }

  const path = flattenToAppURL(pdfPath);

  if (!path) {
    return null;
  }

  return <PDFViewer path={path} />;
}
