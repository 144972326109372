import { useEffect, useState } from "react";
import "./styles.css";

// const cssHideEditorButtons = "#editorModeButtons {display: none}"
// const cssHideOpenButton = "#secondaryOpenFile {display: none}"
// const cssHidePresentationModeButton = "#presentationMode {display: none}"
// const cssHideCurrentPageButton = "#viewBookmark {display: none }"
// const cssToolbarCustomisations = `${cssHideEditorButtons}`
// const cssHideOverflowSeparators = ".visibleMediumView + .horizontalToolbarSeparator { display: none } #viewBookmarkSeparator { display: none }"
// const cssToolbarOverflowMenuCustomisations = `${cssHideOpenButton} ${cssHidePresentationModeButton} ${cssHideCurrentPageButton} ${cssHideOverflowSeparators}`

// const cssToolbarHideSearchButton =
//   ".toolbarButtonWithContainer > #viewFindButton { display: none }";
const fixButtons = ".toolbarButton { padding-inline: 0 }";
const cssToolbarHideRightButtons = `#toolbarViewerRight { display: none }`;
const fixMainContainerWidth = `#mainContainer { min-width: unset; }`;
const viewerExtraStyles = `${cssToolbarHideRightButtons} ${fixButtons} ${fixMainContainerWidth}` ;

export function PDFViewer({ path }) {
  const [elementReady, setElementReady] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "pdf-script";
    script.type = "module";
    script.src = "https://cdn.skypack.dev/pdfjs-viewer-element";
    script.addEventListener("load", () => {
      if (!window.customElements.get("pdfjs-viewer-element")) {
        window.customElements.whenDefined("pdfjs-viewer-element").then(() => {
          setElementReady(true);
        });
      } else {
        setElementReady(true);
      }
    });
    document.body.appendChild(script);
  }, []);

  return elementReady ? (
    <div className="pdf-viewer-wrapper">
      <pdfjs-viewer-element
        src={path}
        viewer-path="/pdfjs"
        viewer-css-theme="DARK"
        viewer-extra-styles={viewerExtraStyles}
      ></pdfjs-viewer-element>
    </div>
  ) : null;
}
