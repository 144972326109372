import {PDFBlockEdit} from "./Edit";
import {PDFBlockView} from "./View";
import {pdfBlockSchema} from "./schema";

import sliderSVG from '@plone/volto/icons/slider.svg';


export default function applyConfig(config) {
  config.blocks.blocksConfig['nsw_pdf'] = {
    id: 'nsw_pdf',
    title: 'PDF',
    icon: sliderSVG,
    group: 'common',
    view: PDFBlockView,
    edit: PDFBlockEdit,
    blockSchema: pdfBlockSchema,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1
  };

  return config;
};

